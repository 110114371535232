import React from 'react';
import styled from 'styled-components';
import PageHeader from '@src/components/common/PageHeader';
import { Hidden } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '@src/components/common/Flex';
import adobeillustrator from '@src/static/adobeillustrator.svg';
import adobephotoshop from '@src/static/adobephotoshop.svg';
import adobexd from '@src/static/procreate.svg';
import adobeaftereffects from '@src/static/adobeaftereffects.svg';
import figma from '@src/static/figma.svg';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const SkillsWrapper = styled.section`
  ${props => props.theme.spacing.sectionBottom};
  position: relative;
  .skill__icons {
    padding: 30px 0;
    @media ${props => props.theme.media.tablet} {
      padding: 10px 0;
    }
    .brands {
      z-index: 1;
      width: 3.8rem;
      color: ${props => props.theme.primaryColor};
    }
    svg {
      color: ${props => props.theme.primaryColor};
    }
  }
  .skills__word-clouds {
    z-index: -1;
    @media ${props => props.theme.media.tablet} {
      display: none;
    }

    p {
      position: absolute;
      color: ${props => props.theme.accentColor};

      left: 0;
      right: 0;
      font-weight: 900;
    }
  }
`;

const WordClouds = () => {
  return (
    <Parallax
      y={['-470px', '-390px']}
      slowerScrollRate={true}
      aria-hidden="true"
      className="skills__word-clouds"
    >
      <p style={{ top: 500, left: -40, fontSize: 17 }}>
        Information Architecture
      </p>
      <p style={{ top: 55, left: 0, fontSize: 27 }}>User Experience Design</p>
      <p style={{ top: 170, left: -70, fontSize: 14 }}>Typography</p>
      <p style={{ top: 450, left: '22%', fontSize: 18 }}>Wireframing</p>
      <p style={{ top: 300, left: '77%', fontSize: 15 }}>Web Design</p>
      <p style={{ top: 100, left: '45%', fontSize: 19 }}>
        User Centered Design
      </p>
      <p style={{ top: 250, left: '99%', fontSize: 17 }}>Digital Art</p>
      <p style={{ top: 300, left: -60, fontSize: 18 }}>Interaction Design</p>
      <p style={{ top: 45, left: '81%', fontSize: 25 }}>Product Design</p>
      <p style={{ top: 270, left: 130, fontSize: 18 }}>Prototyping</p>
      <p style={{ top: 460, left: '55%', fontSize: 18 }}>Agile Methodologies</p>
      <p style={{ top: 300, left: '33%', fontSize: 15 }}>UX Research</p>
      <p style={{ top: 400, left: '97%', fontSize: 15 }}>User Personas</p>
      <p style={{ top: 500, left: '80%', fontSize: 18 }}>Design Thinking</p>
      <p style={{ top: 280, left: '58%', fontSize: 13 }}>User Stories</p>
    </Parallax>
  );
};

const Skills = () => (
  <SkillsWrapper id="skills" style={{ marginBottom: 200 }}>
    <ParallaxProvider>
      <PageHeader>
        Skills & Tools
        <p id="p" style={{ marginBottom: 150 }}>
          I use in my everyday work life.
        </p>
      </PageHeader>

      {/* <SkillPie percent={90} name={"JavaScript"} /> */}
      <Flex
        className="skill__icons"
        justify="space-around"
        align="center"
        style={{ marginBottom: 50 }}
      >
        <img className="brands" src={adobeillustrator} alt=""></img>
        <img className="brands" src={adobephotoshop} alt=""></img>
        <img className="brands" src={adobexd} alt=""></img>
        <img className="brands" src={adobeaftereffects} alt=""></img>
        <img className="brands" src={figma} alt=""></img>
      </Flex>

      <Flex
        className="skill__icons"
        justify="space-around"
        align="center"
        style={{ marginLeft: 7 }}
      >
        <FontAwesomeIcon icon={['fab', 'html5']} size="4x" />
        <FontAwesomeIcon icon={['fab', 'css3-alt']} size="4x" />
        <FontAwesomeIcon icon={['fab', 'sass']} size="4x" />
        <FontAwesomeIcon icon={['fab', 'js-square']} size="4x" />
        <FontAwesomeIcon icon={['fab', 'react']} size="4x" />
      </Flex>
      <Hidden>
        <WordClouds />
      </Hidden>
    </ParallaxProvider>
  </SkillsWrapper>
);

export default Skills;
